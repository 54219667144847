import React from 'react';
import logo from '../../assets/images/logo2.png'; 
import './postlogin.css';

const PostLoginPage = () => {
    return (
        <div className="post-login-container">
            <div className="content">
                <img src={logo} alt="Logo" className="logo" />
                <p className="message">
                    Welcome to Kshatriyas Registry, Website Under Maintenance - will update you once it is launched.
                </p>
            </div>
        </div>
    );
};

export default PostLoginPage;
