import './App.css';
import Home from './screens/home/home';
import './screens/login/login.css';
import Signup from './screens/signup/signup';
import Login from './screens/login/login';
import PostLoginPage from './screens/home/postlogin';
import Connect from './components/connect';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/protectedRoute';
import UserHome from './screens/user_home/userhome'; // Corrected import
import Navbar from './components/navbar/Navbar';
import ForgotPassword from './screens/login/forgotpassword'
import Profile from './screens/profile/profile';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Redirect root path to login page */}
          <Route path="/" element={<PostLoginPage />} />

          {/* Other routes */}
          <Route path='/login' element={<Login />} />  
          <Route path='/home' element={<ProtectedRoute><Home /></ProtectedRoute>} />  
          <Route path='/signup' element={<Signup />} />
          <Route path='/navbar' element={<ProtectedRoute><Navbar /></ProtectedRoute>} />
          <Route path='/forgotpassword' element={<ProtectedRoute><ForgotPassword /></ProtectedRoute>} />
          <Route path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />

          {/* Only load UserHome when navigating to /dashboard 
          <Route path='/dashboard' element={<ProtectedRoute><UserHome /></ProtectedRoute>} />*/}
 
          <Route path="/connect" element={<Connect />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
