import React, { useState }  from "react";
import './Navbar.css';  // Reuse the existing CSS file
import logo2 from '../../assets/images/logo2.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { MenuItems } from "../../screens/user_home/navmenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import '../../screens/profile/customfonts.css';


const Navbar = () => {
    const navigate = useNavigate();

   // Use useState hook to manage state
   const [clicked, setClicked] = useState(false);
   const handleLogout = () => {
    // Clear user data from localStorage or sessionStorage
    localStorage.removeItem('authToken'); // or sessionStorage.removeItem('authToken')

    // Redirect to login page
    navigate('/login', { replace: true });
  };
   const handleClick = () => {
       setClicked(!clicked);  // Toggle the clicked state
   }

   return (
    <nav className="nav-items">
        <div className="logo-container">
            <img src={logo2} alt="Logo" className="navbar-logo" />
        </div>

        <div className="customFont">
            <span style={{ textAlign: 'left', color: '#540000', fontSize: '18px', fontWeight: 'bold',fontFamily: 'Bourjois' }}>KSHATRIYAS REGISTRY</span>
        </div>

        {/* Menu Icon for mobile view toggle */}
        <div className="menu-icons" onClick={handleClick}>
            <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
                              
                    <ul class={clicked ? "navbar-menu active" : "navbar-menu"}>
                        {MenuItems.map((item, index)=> {
                            return (
                                <li key={index}>
                                    <a className={item.cName} href={item.url}>
                                    <i className={item.icon}>
                                    <FontAwesomeIcon icon={item.icon} className="menu-icon" />
                                    </i>
                                    {item.title}
                                    
                                    </a>
                                </li>
                                )
                            })}
                    </ul>                                     
            </nav>
    );
};

export default Navbar;
