import React, { useState } from 'react';
import './profile.css';

const Profile = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    cc: '',
    contactNumber: '',
    aadhar: '',
    gender: 'Male'
  });
  
	const [isGraduationOpen, setIsGraduationOpen] = useState(false);
  
	const toggleGraduationDetails = () => {
	  setIsGraduationOpen(!isGraduationOpen);
	};
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
  };

  return (
    <div className="personal-details-container">
  	<div class="side-nav">
    		<div class="brand-nav-list">
      			<div class="nav-brand">
        				<div class="logo">
          					<div class="logo1">
            						<img class="union-icon" alt="" src="Union.svg" />
          					</div>
        				</div>
      			</div>
      			<div class="nav-list">
        				<div class="nav-item">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Home.svg" />
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Personal Details</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item1">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Edit Square.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard1">Education</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Image.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Occupation</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Document.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Family</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item4">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Chat.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Matrimony</div>
            						</div>
          					</div>
          					<div class="trailing-icon-badge">
            						<div class="badge">
              							<div class="number-notification-badge">
                								<div class="div">1</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Category.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Appearance</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Bag.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Plugins</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="3 User.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Users</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Setting.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Settings</div>
            						</div>
          					</div>
        				</div>
        				<div class="nav-item">
          					<div class="nav-brand">
            						<div class="leading-icon">
              							<img class="home-icon" alt="" src="Filter.svg"/>
              							
            						</div>
            						<div class="leading-icon">
              							<div class="dashboard">Tools</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		<div class="logout-toogle">
      			<div class="nav-item4">
        				<div class="nav-brand">
          					<div class="leading-icon">
            						<img class="home-icon" alt="" src="moon.svg"/>
            						
          					</div>
          					<div class="leading-icon">
            						<div class="dashboard">Light Mode</div>
          					</div>
        				</div>
        				<div class="trailing-icon-badge1">
          					<div class="badge">
            						<div class="toogle">
              							<div class="toogle-child">
              							</div>
              							<div class="on">ON</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div class="nav-item11">
        				<div class="nav-brand">
          					<div class="leading-icon">
            						<img class="home-icon" alt="" src="Logout.svg"/>
            						
          					</div>
          					<div class="leading-icon">
            						<div class="dashboard1">Logout</div>
          					</div>
        				</div>
      			</div>
    		</div>
  	</div>

      <div className="form-section">
        <h2 className="form-title">Personal Details</h2>
        <p className="form-description">
          Follow the instructions to make it easier to register and you will be able to explore inside.
        </p>
        <form onSubmit={handleSubmit}>
  	
  	<div class="erd">
    		<img class="erd-child" alt="" src="Ellipse 4.svg"/>
    		
    		<div class="education">Education</div>
    		<div class="field">
      			<div class="sslc-wrapper">
        				<div class="sslc">SSLC</div>
      			</div>
				  <div class="field1">
    <div class="last-name-wrapper">
        
        <label for="year" class="year-label">Year</label>

    </div>	
	<input type="text" class="year-input" id="year" placeholder=" " required/>
</div>


      			<div class="field2">
        				<div class="last-name-container">
          					<div class="last-name1">Class</div>
        				</div>
        				<img class="vector-icon" alt="" src="Vector.svg"/>
        				
      			</div>
      			<div class="field3">
        				<div class="last-name-frame">
          					<div class="last-name1">Percentage</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field4">
        				<div class="last-name-container">
          					<div class="last-name1">Marks</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field5">
        				<div class="last-name-wrapper1">
          					<div class="last-name1">Total Marks</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field6">
        				<div class="last-name-wrapper2">
          					<div class="last-name5">School Address</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field7">
        				<div class="last-name-wrapper3">
          					<div class="last-name6">School Name</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
    		</div>
    		<div class="field8">
      			<div class="sslc-wrapper">
        				<div class="sslc">HSC</div>
      			</div>
      			<div class="field1">
        				<div class="last-name-wrapper">
          					<div class="last-name">Year</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field2">
        				<div class="last-name-container">
          					<div class="last-name1">Class</div>
        				</div>
        				<img class="vector-icon1" alt="" src="Vector.svg"/>
        				
      			</div>
      			<div class="field3">
        				<div class="last-name-container">
          					<div class="last-name1">Percentage</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field4">
        				<div class="last-name-container">
          					<div class="last-name1">Marks</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field5">
        				<div class="last-name-container">
          					<div class="last-name1">Total Marks</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field6">
        				<div class="last-name-wrapper2">
          					<div class="last-name5">School Address</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
      			<div class="field7">
        				<div class="last-name-wrapper3">
          					<div class="last-name6">School Name</div>
        				</div>
						<input type="text" class="year-input" id="year" placeholder=" " required/>
      			</div>
    		</div>
    		<div className="erd-inner">
      <div className="graduation-parent">
        <div className="graduation">
          <div className={isGraduationOpen ? 'closeddefault1' : 'closeddefault'}>
            <div className="sslcWrapper">
              <div className="sslc">Graduation</div>
            </div>
            <div className="addWrapper">
              <div className="add" />
            </div>
            <img
              className="minusIcon"
              alt="minus icon"
              src="Minus.svg"
              onClick={toggleGraduationDetails}
            />
          </div>

          {isGraduationOpen && (
            <div className="graduation-details">
              <div className="field">
                <div className="lastNameWrapper">
                  <div className="lastName">Class</div>
                </div>
                <img className="vectorIcon" alt="" src="Vector.svg" />
              </div>
              <div className="field1">
                <div className="lastNameContainer">
                  <div className="lastName">Percentage</div>
                </div>
              </div>
              <div className="field2">
                <div className="lastNameFrame">
                  <div className="lastName2">Degree</div>
                </div>
              </div>
              <div className="field3">
                <div className="frameDiv">
                  <div className="lastName3">Specialization</div>
                </div>
              </div>
              <div className="field4">
                <div className="lastNameWrapper1">
                  <div className="lastName4">College Address</div>
                </div>
              </div>
              <div className="field5">
                <div className="lastNameWrapper2">
                  <div className="lastName5">College Name</div>
                </div>
              </div>
              <img className="minusIcon" alt="" src="Minus.svg" onClick={toggleGraduationDetails} />
              <div className="field6">
                <div className="lastNameWrapper3">
                  <div className="lastName6">Year</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  	</div>

          <button type="submit" className="submit-button">Next</button>
        </form>
      </div>
    </div>
  );
};


export default Profile;


