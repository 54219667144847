import React, { useState } from 'react';
import axios from 'axios';  // To make HTTP requests
import './forgotpassword.css';
import logo from '../../assets/images/logo2.png'; 



const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [step, setStep] = useState(1);  // To track the current step
    const [error, setError] = useState('');
    const [isCodeVerified, setIsCodeVerified] = useState(false);

    // Handle sending verification code
    const handleSendVerificationCode = async () => {
        try {
            const response = await axios.post('http://localhost/send_code.php', { email });
            if (response.data.success) {
                setStep(2);  // Move to verification step
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            console.error(err);
            setError('Failed to send verification code.');
        }
    };

    // Handle verifying the code
    const handleVerifyCode = async () => {
        try {
            const response = await axios.post('http://localhost/verify_code.php', { email, verificationCode });
            if (response.data.success) {
                setIsCodeVerified(true);  // Code is correct, move to the next step
                setStep(3);  // Show new password fields
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            console.error(err);
            setError('Verification failed.');
        }
    };

    // Handle resetting password
    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('http://localhost/reset_password.php', {
                email,
                newPassword
            });
            if (response.data.success) {
                alert('Password reset successfully!');
                window.location.href = '/login';  // Redirect to login page
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            console.error(err);
            setError('Failed to reset password.');
        }
    };

    return (
        <div className="hold-transition login-page">
            <nav className="nav-login">
                <div className="container-fluid nav-wrapper">
                    <div className="nav-box">
                        <div>
                            <img src={logo} alt="Logo" />
                        </div>
                    </div>
                </div>
            </nav>
            <section className="accouts-bg">
                <div className="container-fluid">
                    <div className="row login-wrap clearfix" style={{ height: '600px', width: '4500px' }}>
                        <div
                            className="col-md-6 col-xs-0 left-box"
                            style={{
                                backgroundColor: "#f6efc8",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '300px'
                            }}
                        >
                            <div className="left-box-content_logo" style={{ textAlign: 'center', padding: '10px' }}>
                                <img src={logo} alt="Logo" style={{ width: '300px' }} />
                            </div>
                            <div className="left-box-conten_logo" style={{ textAlign: 'center', padding: '20px' }}>
                                <span style={{ textAlign: 'left', color: 'brown', fontSize: '18px', fontWeight: 'bold' }}>KSHATRIYAS REGISTRY</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 right-box" style={{
                                background: 'linear-gradient(to bottom, #f2bc39, #d60808)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '2000px'
                            }}>
                            <div className="register-login">
                            <h2>Forgot Password</h2>
                            {error && <p className="error-message">{error}</p>}

                            {step === 1 && (
                                <>
                                    <label>Email Address</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email"
                                        required
                                    />
                                    <button onClick={handleSendVerificationCode}>Send Verification Code</button>
                                </>
                            )}

                            {step === 2 && (
                                <>
                                    <label>Verification Code</label>
                                    <input
                                        type="text"
                                        value={verificationCode}
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                        placeholder="Enter verification code"
                                        required
                                    />
                                    <button onClick={handleVerifyCode}>Verify Code</button>
                                </>
                            )}

                            {step === 3 && (
                                <>
                                    <label>New Password</label>
                                    <input
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="Enter new password"
                                        required
                                    />
                                    <label>Confirm New Password</label>
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm new password"
                                        required
                                    />
                                    <button onClick={handleResetPassword}>Confirm</button>
                                </>
                            )}
                                    </div>
                                </div>
                        </div>
                        </div>
                        </section>
                </div>
    );
};

export default ForgotPassword;
