import React, { useState } from "react";
import './home.css';
import logo from '../../assets/images/logo.png';

const Home = () => {
    const [email] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();

        fetch('http://localhost/kr/login.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: email,
                password: password
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                // Redirect to the dashboard or home page
                window.location.href = '/home';
            } else {
                setError(data.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
        });
    };

    return (
        <div className="hold-transition login-page">
            <nav className="nav-login">
                <div className="container-fluid nav-wrapper">
                    <div className="nav-box">
                        <div>
                            <img src={logo} alt="Logo" />
                        </div>
                    </div>
                </div>
            </nav>

            <section className="accouts-bg2">
                <div className="container-fluid">
                    <div className="row login-wrap clearfix" style={{ height: '500px' }}>
                        <div
                            className="col-md-6 col-xs-0 left-box"
                            style={{
                                backgroundColor: "#f6efc8",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <div className="left-box-content_logo" style={{ textAlign: 'center', padding: '80px' }}>
                                <img src={logo} alt="Logo" style={{ width: '180px' }} />
                            </div>
                            <div className="left-box-conten_logo" style={{ textAlign: 'center', padding: '20px' }}>
                                <span style={{ textAlign: 'left', color: 'brown', fontSize: '18px', fontWeight: 'bold' }}>KSHATRIYAS REGISTRY</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 right-box" style={{ width: '300px' }}>
                            <div className="register-login">
                                <form onSubmit={handleLogin}>
                                    <div className="form-content">
                                        <div className="register-login__nav">
                                            <div className="login active">Log In</div>
                                            <div className="signup"><a href="/signup" id="signup_id">Sign Up</a></div>
                                        </div>
                                        <ul className="register-login__form">
                                            <li className="email-address form-group form-icon-left">
                                                <input
                                                    type="text"
                                                    name="username"
                                                    id="Email"
                                                    placeholder="Email Address"
                                                    autoComplete="off"
                                                    /* value={email} 
                                                    onChange={(e) => setEmail(e.target.value)} */
                                                />
                                                
                                                
                                            </li>
                                            <li className="password form-group form-icon-left">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    id="Password"
                                                    placeholder="Password"
                                                    autoComplete="off"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <div className="text-error text-danger"></div>
                                               
                                            </li>
                                            {error && (
                                                <li className="text-error error-info" style={{ marginTop: '10px', color: 'red' }}>
                                                    {error}
                                                </li>
                                            )}
                                            <li className="confirm-button form-group" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <input type="submit" id="login" style={{ width: '100%' }} value="Log In" />
                                                <div className="spinner">
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                </div>
                                            </li>
                                            <li className="other-options" style={{ marginTop: '20px' }}>
                                                <div className="forget-password">
                                                    <a href="/">Forget password?</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
