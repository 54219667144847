// navmenu.js
import { faUser, faFeed, faBriefcase, faUsers, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';

export const MenuItems = [
    {
        title: "Profile",
        url: "/profile",
        cName: "nav-links",
        icon: faUser,
    },
    {
        title: "Feeds",
        url: "/feeds",
        cName: "nav-links",
        icon: faFeed,
    },
    {
        title: "Matrimony",
        url: "/matrimony",
        cName: "nav-links",
        icon: faUserGroup,
    },
    {
        title: "Business",
        url: "/business",
        cName: "nav-links",
        icon: faBriefcase,
    },
    {
        title: "About Us",
        url: "/aboutus",
        cName: "nav-links",
        icon: faUsers,
    },
    {
        title: "Logout",
        url: "/login",
        cName: "nav-links",
        icon: faSignOut,
    },
];
