import React, { useState, useEffect } from 'react';

const DbConnectionStatus = () => {
    const [connectionStatus, setConnectionStatus] = useState(null);

    useEffect(() => {
        // Fetch the database connection status from the PHP backend
        fetch('http://localhost/dbconnect.php')
            .then(res => res.json())
            .then(results => setConnectionStatus(results))
            .catch(error => {
                console.error('Error fetching the connection status:', error);
                setConnectionStatus({ connected: false, message: 'Failed to connect to the database.' });
            });
    }, []);

    return (
        <div>
            <h1>Database Connection Status</h1>
            {connectionStatus ? (
                <div>
                    <p>{connectionStatus.message}</p>
                    {connectionStatus.connected ? (
                        <p style={{ color: 'green' }}>Connected to the database successfully!</p>
                    ) : (
                        <p style={{ color: 'red' }}>Failed to connect to the database.</p>
                    )}
                    {connectionStatus.data && (
                        <div>
                            <h2>Data from Database:</h2>
                            <ul>
                                {connectionStatus.data.map((item, index) => (
                                    <li key={index}>{JSON.stringify(item)}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            ) : (
                <p>Checking connection...</p>
            )}
        </div>
    );
};

export default DbConnectionStatus;
