import React, { useState, useEffect, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import './signup.css';  
import logo from '../../assets/images/logo2.png'; 


const Signup = () => {
    const [referralCode, setReferralCode] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [day, setDay] = useState(1);
    const [month, setMonth] = useState(1);
    const [year, setYear] = useState(2024);
    const [mobileNumber, setMobileNumber] = useState('');
    const [isReferralValid, setIsReferralValid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [daysInMonth, setDaysInMonth] = useState([]);
    const modalContentRef = useRef(null);
    const navigate = useNavigate();
  
    // Handle form field changes
    const handleReferralChange = (e) => {
        const value = e.target.value;
        setReferralCode(value);
        // Update to match the specific referral code
        setIsReferralValid(value === 'SAMIKSREG'); 
    };
    
    const handleDayChange = (e) => setDay(e.target.value);
    const handleMonthChange = (e) => setMonth(e.target.value);
    const handleYearChange = (e) => setYear(e.target.value);
    const handleGenderChange = (e) => setGender(e.target.value);
  
    const floatFocus = (e) => {
        e.target.parentElement.classList.add("e-input-focus");
    };

    const floatBlur = (e) => {
        if (!e.target.value) {
            e.target.parentElement.classList.remove("e-input-focus");
        }
    };

    // Handle the scroll event of the modal
    const handleScroll = () => {
      const contentHeight = modalContentRef.current.scrollHeight;
      const currentScrollPosition = modalContentRef.current.scrollTop + modalContentRef.current.clientHeight;
      if (currentScrollPosition >= contentHeight) {
        setIsScrolledToBottom(true);
      }
    };
  
    // Handle terms checkbox
    const handleTermsChange = (e) => setIsTermsAccepted(e.target.checked);
  
   // Handle modal open and close
const handleSignupClick = (e) => {
    if (e && e.preventDefault) {
        e.preventDefault(); // Prevent default only if event exists and preventDefault is callable
    }
    setIsModalOpen(true);  // Open modal on signup button click
};

const handleModalClose = () => {
    setIsModalOpen(false);
    setIsScrolledToBottom(false);
    setIsTermsAccepted(false);
};

// Updated handleConfirmSignUp to pass the event
const handleConfirmSignUp = (e) => {
    // Close the modal and proceed to save data
    handleModalClose();
    handleFormSubmit(e); // Pass the event to handleFormSubmit
};

const handleFormSubmit = async (e) => {
    if (e && e.preventDefault) {
        e.preventDefault(); // Prevent default form behavior only if event exists
    }

    // Convert day, month, and year to a YYYY-MM-DD format for birthYear
    const birthYear = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

    // Prepare data to be sent to the backend
    const formData = {
        referralCode,
        firstname,
        lastname,
        email,
        gender,
        birthYear, // Now sending birthYear in YYYY-MM-DD format
        mobileNumber,
    };

    // Log the form data to the console for debugging
    console.log('Data sent to PHP:', formData);

    // Send data to backend PHP script
    try {
        const response = await fetch('http://localhost/signup.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const result = await response.json();
        console.log('Server response:', result); // Log server response for debugging

        if (response.ok) {
            alert(result.message || 'Sign up successful!');
            navigate('/login'); // Redirect to login page
        } else {
            alert(result.error || 'An error occurred during signup.');
        }
    } catch (error) {
        console.error('Error during signup:', error);
        alert('Failed to sign up. Please try again later.');
    }
};



  
    useEffect(() => {
      // Update days in the selected month and year
      const daysInMonth = new Date(year, month, 0).getDate();
      setDaysInMonth(Array.from({ length: daysInMonth }, (_, i) => i + 1));
    }, [month, year]);

    return (
        <div className="hold-transition login-page">
            <nav className="nav-login">
                <div className="container-fluid nav-wrapper">
                    <div className="nav-box">
                        <div>
                            <img src={logo} alt="Logo" />
                        </div>
                    </div>
                </div>
            </nav>
            <section className="accouts-bg">
                <div className="container-fluid">
                    <div className="row login-wrap clearfix" style={{ height: '600px', width: '4500px' }}>
                        <div
                            className="col-md-6 col-xs-0 left-box"
                            style={{
                                backgroundColor: "#f6efc8",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '300px'
                            }}
                        >
                            <div className="left-box-content_logo" style={{ textAlign: 'center', padding: '10px' }}>
                                <img src={logo} alt="Logo" style={{ width: '300px' }} />
                            </div>
                            <div className="left-box-conten_logo" style={{ textAlign: 'center', padding: '20px' }}>
                                <span style={{ textAlign: 'left', color: 'brown', fontSize: '18px', fontWeight: 'bold' }}>KSHATRIYAS REGISTRY</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 right-box" style={{
                                background: 'linear-gradient(to bottom, #f2bc39, #d60808)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '2000px'
                            }}>
                            <div className="register-login">
                            <form method="post" onSubmit={handleSignupClick}>
        <div className="form-content">
          <div className="register-login__nav">
            <div className="signup">
              <a href="/signup" id="signup_id">
                Sign Up
              </a>
            </div>
          </div>

          <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
            <div className="e-input-group">
              <input
                className="e-input-first"
                type="text"
                value={referralCode}
                onChange={handleReferralChange}
                onFocus={floatFocus}
                onBlur={floatBlur}
                onClick={floatFocus}
                placeholder=" "
              />
              <label className="floating-label">Referral Code</label>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <div className="e-input-group-wrapper">
              <div className="e-input-group">
                <input
                  className="e-input-first"
                  type="text"
                  placeholder=" "
                  disabled={!isReferralValid}
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  onFocus={floatFocus}
                onBlur={floatBlur}
                onClick={floatFocus}
                />
                <label className="floating-label">First Name</label>
              </div>
              <div className="e-input-group">
                <input
                  className="e-input-first"
                  type="text"
                  placeholder=" "
                  disabled={!isReferralValid}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  onFocus={floatFocus}
                onBlur={floatBlur}
                onClick={floatFocus}
                />
                <label className="floating-label">Last Name</label>
              </div>
            </div>
          </div>

          <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
            <div className="e-input-group e-rtl">
              <input
                className="e-input"
                type="email"
                placeholder=" "
                disabled={!isReferralValid}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={floatFocus}
                onBlur={floatBlur}
                onClick={floatFocus}
              />
              <label className="floating-label">Email</label>
            </div>
          </div>

          <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
            <div className="gender-wrapper">
              <label className="gender-label">Gender</label>
              <span className="gender-option">
                <input
                  type="radio"
                  className="gender-input"
                  id="female"
                  name="gender"
                  value="female"
                  onChange={handleGenderChange}
                />
                <label htmlFor="female" className="gender-label-option">
                  Female
                </label>
              </span>
              <span className="gender-option">
                <input
                  type="radio"
                  className="gender-input"
                  id="male"
                  name="gender"
                  value="male"
                  onChange={handleGenderChange}
                />
                <label htmlFor="male" className="gender-label-option">
                  Male
                </label>
              </span>
              <span className="gender-option">
                <input
                  type="radio"
                  className="gender-input"
                  id="custom"
                  name="gender"
                  value="custom"
                  onChange={handleGenderChange}
                />
                <label htmlFor="custom" className="gender-label-option">
                  Transgender
                </label>
              </span>
            </div>
          </div>

          <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
            <div className="gender-wrapper">
              <label className="gender-label">Date of Birth</label>
              <span className="gender-option">
                <select
                  aria-label="Day"
                  name="birthday_day"
                  id="day"
                  title="Day"
                  className="_9407 _5dba _9hk6 _8esg"
                  value={day}
                  onChange={handleDayChange}
                  style={{ marginRight: '10px' }}
                >
                  {daysInMonth.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </span>
              <span className="gender-option">
                <select
                  aria-label="Month"
                  name="birthday_month"
                  id="month"
                  title="Month"
                  className="_9407 _5dba _9hk6 _8esg"
                  value={month}
                  onChange={handleMonthChange}
                  style={{ marginRight: '10px' }}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {new Date(0, i).toLocaleString('default', { month: 'short' })}
                    </option>
                  ))}
                </select>
              </span>
              <span className="gender-option">
                <select
                  aria-label="Year"
                  name="birthday_year"
                  id="year"
                  title="Year"
                  className="_9407 _5dba _9hk6 _8esg"
                  value={year}
                  onChange={handleYearChange}
                >
                  {Array.from({ length: 120 }, (_, i) => (
                    <option key={2024 - i} value={2024 - i}>
                      {2024 - i}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <div className="e-input-group-wrapper">
              <div className="country_code">
                <select className="e-input-auto" onFocus={floatFocus} onBlur={floatBlur} onClick={floatFocus}>
                  <option value="1">+91 (India)</option>
                  <option value="2">+1 (USA)</option>
                  <option value="44">+44 (UK)</option>
                </select>
              </div>
              <div className="e-input-group">
                <input
                  className="e-input-first"
                  type="text"
                  placeholder=" "
                  value={mobileNumber}
                  onFocus={floatFocus}
                onBlur={floatBlur}
                onClick={floatFocus}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
                <label className="floating-label">Mobile Number</label>
              </div>
            </div>
          </div>

          <div className="input-group">
            <button type="submit" className="signup-button">
              Sign Up
            </button>
          </div>

          <p className="terms">
            Having an Account? <a href="/login">Sign In</a>
          </p>


                {isModalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleModalClose}>&times;</span>
                            <h2>Kshatriyas Registry Privacy Policy</h2>
                            <div
                                className="modal-body"
                                ref={modalContentRef}
                                onScroll={handleScroll}
                            >

<p align= "left">
<b>Kshatriyasregistry.com </b> ("us", "we", “Kshatriyas Registry” or " Kshatriyasregistry.com ") is committed to respecting the privacy rights of our customers, visitors, and other users of the Kshatriyasregistry.com services ("Services") provided via the Kshatriyasregistry.com website (collectively, "Website"). We created this Website Privacy Policy ("Privacy Policy") to give you confidence as you visit and use the Website, and to demonstrate our commitment to fair information practices and the protection of privacy. This Privacy Policy is only applicable to the Website, and not to any other websites that you may be able to access from the Website or any website of Kshatriyasregistry.com partners, each of which may have data collection, storage, and use practices and policies that differ materially from this Privacy Policy. Your use of the Website is governed by this Privacy Policy and the Terms of Use. If you are registering an account or using the Website on behalf of an individual or entity other than yourself, you represent that you are authorized by such individual or entity to accept this Privacy Policy on such individual's or entity's behalf.
<br />
<b>Traffic Data Collected : </b>
We automatically track and collect the following categories of information when you visit our website: (1) IP addresses; (2) domain servers; (3) types of computers accessing the Site; (4) types of web browsers used to access the Website; (5) referring source which may have sent you to the Website; and (6) other information associated with the interaction of your browser and the Website (collectively "Traffic Data").
<br /><b>Personal Information Collected : </b>

In order for you to access certain areas of the Website, we may require you to provide us with certain information that personally identifies you ("Personal Information"). Personal Information includes the following categories of information: (1) Contact Data (such as your e-mail address, phone number, Aadhar Number and Kshatriyasregistry.com password); (2) Demographic Data (such as your gender, your date, place & time of birth and your PIN code); (3) Personal Data (Such as your height, weight, color complexion, appearance, personal preferences and choices of your food, sports and entertainment activities).  (4) Social Data (such as data of your family, friends, relatives and the data you use in various Websites including Kshatriyasregistry.com in the internet). (5) Any other data related to you which may require for the access or usage of Kshatriyasregistry.com.  If you communicate with us by, for example, e-mail or letter, any information provided in such communication may be collected as Personal Information.
<br />
<b>Interactive and Installed Tools : </b>
We also collect other information, some of which may be Personal Information that you voluntarily provide to us when you choose to use some of our interactive tools and services ("Interactive Tools"). We also collect information you provide voluntarily in free-form text boxes on the Website and through responses to surveys, questionnaires and the like. Some of the free Interactive Tools on our Website ask you for health-related Personal Information. You may elect to use certain Services available through the Website that require you to download and install software on your computer ("Installed Tools"). The Installed Tools may collect and transmit information from your computer system solely relating to use of the Installed Tools and for the purpose of providing you the relevant Services.
<br />
<b>Cookies : </b>
"Cookies" are small computer files that are transferred to your computer's hard drive that contain information such as user ID, user preferences, lists of pages visited and activities conducted while browsing the Website. At your option, expense and responsibility, you may block cookies or delete cookies from your hard drive. However, by disabling cookies, you may not have complete access to the entire set of features of the Website.
Generally, we use "cookies" to customize your experience on our Website and to store your password so you do not have to re-enter it each time you visit the Website.
In addition, our business partners may use cookies to provide us with anonymous data and information regarding the use of our Website. Specifically, some of our business partners use cookies to show Kshatriyasregistry.com ads on other sites on the internet as a result of you using the Website. Such cookies do not contain any Personal Information. You may opt out of receiving cookies placed by such third party vendors by visiting the Network Advertising Initiative opt out page.
Other cookies used by our business partners may collect other non-personally identifying information, such as the computer's IP address, type of operating system, type of internet browsing software, what web pages were viewed at what time, the geographic location of your internet service provider and demographic information, such as gender and age range. This information is used to provide kshatriyasregistry.com with more information about our user's demographics and internet behaviors. You may find out more about the information collected and how to opt-out of receiving these cookies by visiting our partner's website.
We do not link the information stored in these cookies directly to any of your Personal Information you submit while on the Website.
<br />
<b>Web Beacons : </b>
In limited circumstances we also may use "Web Beacons" to collect anonymous, non-personal information about your use of our Website and the website of selected sponsors and advertisers, and your use of emails, special promotions or newsletters we send to you. "Web Beacons" are tiny graphic image files imbedded in a web page or email that provide a presence on the web page or email and send back to its home server information from the user's browser. The information collected by web beacons allows us to statistically monitor how many people are using the Website and selected sponsors' and advertisers' websites, or opening our emails, and for what purposes.
<br />
<b>Website Analytics : </b>
We may use third party website analytics services in connection with the Website. These website analytics services do not collect Personal Information that you do not voluntarily enter into the Site. These services do not track your browsing habits across websites which do not use their services. We are using the information collected from these services to find usability problems to make the Website easier to use. The recordings will never identify you or your account.
<br />
<b>Storage : </b>
We store all Traffic Data and review postings indefinitely, even after "deletion," and may archive such information elsewhere. We store all Personal Information until you request that we modify or delete it, in which case we may still wind up retaining some of that information for the reasons described in Section 20 below.
<br /><b>Kshatriyas Registry Use of Your Information : </b>
We may use your Personal Information to recommend certain resources. We may use your Contact Data to send you information about the Website or our products or Services, to contact you when necessary, including to remind you of upcoming or follow-up events, and in conjunction with your use of certain Interactive Tools. We may use your Demographic Data, your Traffic Data and other Data to customize and tailor your experience on the Website, in emails and in other communications, displaying content that we think you might be interested in and according to your preferences. You agree that Kshatriyasregistry.com may use Personal Information to allow the Website users to make a call, message, email or direct contact with you if you willingly accept. We may also use your de-identified Personal Information to run (or authorize third parties to run) statistical research. Such research would only use your Personal Information in an anonymous manner that cannot be tied directly back to you.
<br /><b>Sharing of Information : </b>
We share certain categories of information we collect from you in the ways described in this Privacy Policy, including as described below:
You may choose to authorize Kshatriyasregistry.com to share your Personal Information with selected Providers, contacts or users. For example, if you elect to complete any other modules in Kshatriyasregistry.com.
We may share your Contact Data, Demographic Data, Personal Data or any other Data  with Providers, contacts or users you choose to schedule on the Website.
In order to customize your advertising interactions, we may share Personal Information with advertisers and other third parties only on an aggregate (i.e., non-personally-identifiable) basis.
We may share your de-identified Personal Information with third parties to enable them to run statistical research on individual or aggregate health or medical trends.
We share Personal Information and Traffic Data with our business partners who assist us by performing core services (such as hosting, billing, fulfillment, or data storage and security) related to our operation of the Website and/or by making certain Interactive Tools available to our users. Those business partners shall be bound to uphold the same standards of security and confidentiality that we have promised to you in this Privacy Policy, and they will only use your Contact Data and other Personal Information to carry out their specific business obligations to Kshatriyasregistry.com and to provide your requested website services.
We may transfer information about you to another company in connection with a merger, sale or acquisition by or of Kshatriyasregistry.com. In this event, we will use reasonable efforts to notify you before information about you is transferred and becomes subject to a different privacy policy. Kshatriyasregistry.com does not share, sell, rent or trade your Personal Information with any third parties for their promotional purposes.
<br /><b>User Choice : </b>
You may choose not to provide us with any Personal Information. In such an event, you can still access and use part of the website; however, you will not be able to access and use those portions of the Website that require your Personal Information.
<br /><b>Confidentiality and Security : </b>
Except as otherwise provided in this Privacy Policy, we will keep your Personal Information private and will not share it with third parties, unless we believe in good faith that disclosure of your Personal Information or any other information we collect about you is necessary to: (1) comply with a court order or other legal process; (2) protect the rights, property or safety of Kshatriyasregistry.com or another party; (3) enforce our Terms of Use; or (4) respond to claims that any posting or other content violates the rights of third-parties.
<br /><b>Public Information : </b>
Any information that you may reveal in a review posting or other online discussion or forum is intentionally open to the public and is not in any way private. You should think carefully before disclosing any personally identifiable information in any public forum. What you have written may be seen and/or collected by third parties and may be used by others in ways we are unable to control or predict.
<br /><b>Security : </b>
The security of your Personal Information is important to us. We follow generally accepted industry standards to protect the Personal Information submitted to us, both during transmission and once we receive it. For example, when you enter sensitive information on our Website, we encrypt that information using secure socket layer technology (SSL).
Although we make good faith efforts to store Personal Information in a secure operating environment that is not open to the public, you should understand that there is no such thing as complete security, and we do not guarantee that there will be no unintended disclosures of your Personal Information. If we become aware that your Personal Information has been disclosed in a manner not in accordance with this Privacy Policy, we will use reasonable efforts to notify you of the nature and extent of the disclosure (to the extent we know that information) as soon as reasonably possible and as permitted by law.

<br /><b>Lost or Stolen Information : </b>
You must promptly notify us if your Contact Data is lost, stolen, or used without permission. In such an event, we will remove that Contact Data from your account and update our records accordingly.
<br /><b>Updates and Changes to Privacy Policy : </b>
We reserve the right, at any time, to add to, change, update, or modify this Privacy Policy so please review it frequently. If we do, then we will notify you here, as well as by posting a notice on our Site and, where appropriate, a link to the modified policy so that you can review it. In all cases, use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.
<br /><b>Controlling Your Personal Information : </b>
As a registered user of the Website, you can modify some of the Personal Information you have included in your profile or change your username by logging in and accessing your account. Upon your request, Kshatriyasregistry.com will use commercially reasonable efforts to delete your account and the Personal Information in your profile; however, it may be impossible to remove your account without some residual information being retained by Kshatriyasregistry.com. We have no obligation to delete any data or information you provide in the Website. Registered users who wish to close their account should contact privacy@kshatriyasregistry.com.
<br /><b>Links to Other Websites : </b>
The Website contains links to third party websites to which kshatriyasregistry.com has no affiliation. We do not share your personal information with those websites and is not responsible for their privacy practices. Some web sites may have the look and feel of our Website. Please be aware that you may be on a different website and that this Privacy Policy only covers our Website. Should you decide to visit one of these third party websites, we suggest that you read its privacy policy.
<br /><b>Contacts : </b>
If you have any comments, concerns or questions about this Privacy Policy, please contact us at privacy@kshatriyasregistry.com                                   
                                </p>
                            </div>  
                            {isScrolledToBottom && (
                                <div className="accept-terms">
                                    <input
                                        type="checkbox"
                                        id="acceptTerms"
                                        checked={isTermsAccepted}
                                        onChange={handleTermsChange}
                                    />
                                    <label htmlFor="acceptTerms">I accept the terms and conditions</label>
                                </div>
                            )}
                           <button
                  type="button"
                  className="confirm-signup-button"
                  disabled={!isTermsAccepted || !isReferralValid}
                  onClick={handleConfirmSignUp}
                >
                  Confirm Sign Up
                </button>
                        </div>
                    </div>
                )}
                </div>
                                        </form>
                                    </div>
                                </div>
                        </div>
                        </div>
                        </section>
                </div>
                
           
      
    );
};

export default Signup;


