import React, { useState, useEffect } from "react";
import './login.css';  // Reuse the existing CSS file
import logo from '../../assets/images/logo2.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '@fortawesome/fontawesome-free/css/all.min.css';

const Login = () => {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize navigate
    const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

   
      
    const handleLogin = (e) => {
        e.preventDefault();

        // Clear any previous error message
        setError('');

        // Send the credentials to the PHP backend
        fetch('https://kshatriyasregistry.com/login.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                userid: userId,
                password: password
            })
        })
        .then(response => {
            // First, check if the response is OK (status code 200)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.status === 'success') {
                localStorage.setItem('authToken', data.token);
                navigate('/postlogin');
            } else {
                setError(data.message || 'Invalid credentials');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError('An error occurred while trying to log in.');
        });
        
    };

    const floatFocus = (e) => {
        e.target.parentElement.classList.add("e-input-focus2");
    };

    const floatBlur = (e) => {
        if (!e.target.value) {
            e.target.parentElement.classList.remove("e-input-focus2");
        }
    };
    const togglePasswordVisibility = () => {
        const passwordField = document.querySelector('.e-input-first2[type="password"]');
        if (passwordField) {
            if (passwordField.type === 'password') {
                passwordField.type = 'text';
            } else {
                passwordField.type = 'password';
            }
        }
        setPasswordVisible((prevState) => !prevState);
    };  
    
    return (
        <div className="hold-transition login-page">
            <nav className="nav-login">
                <div className="container-fluid nav-wrapper">
                    <div className="nav-box">
                        <div>
                            <img src={logo} alt="Logo" />
                        </div>
                    </div>
                </div>
            </nav>
            <section className="accouts-bg">
                <div className="container-fluid">
                    <div className="row login-wrap clearfix" style={{ height: '600px', width: '1500px' }}>
                        <div
                            className="col-md-6 col-xs-0 left-box"
                            style={{
                                backgroundColor: "#f6efc8",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '600px'
                            }}
                        >
                            <div className="left-box-content_logo" style={{ textAlign: 'center', padding: '10px' }}>
                                <img src={logo} alt="Logo" style={{ width: '500px' }} />
                            </div>
                            <div className="left-box-conten_logo" style={{ textAlign: 'center', padding: '20px' }}>
                                <span style={{ textAlign: 'left', color: 'brown', fontSize: '18px', fontWeight: 'bold' }}>KSHATRIYAS REGISTRY</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 right-box" style={{
                                background: 'linear-gradient(to bottom, #f2bc39, #d60808)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '400px',  // Adjusted width to reduce the size
                                padding: '20px',
                                borderRadius: '8px'
                            }}>
                            <div className="register-login2">
                            <form onSubmit={handleLogin}>
                                <div className="form-content">
                                    <div className="register-login__nav">
                                        <div className="login">L O G I N</div>
                                    </div>
                                    {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                    
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                        <div className="e-input-group2-wrapper">
                                            
                                            <div className="e-input-group2">
                                            <span className="icon avatar-icon">
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </span>
                                                <input
                                                    className="e-input-first2"
                                                    type="text"
                                                    onFocus={floatFocus}
                                                    onBlur={floatBlur}
                                                    onClick={floatFocus}
                                                    placeholder=" "
                                                    value={userId}
                                                    onChange={(e) => setUserId(e.target.value)}
                                                />
                                                <label className="floating-label2">User ID</label>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                        <div className="e-input-group2-wrapper">
                                            <div className="e-input-group2">
                                                <input
                                                    className="e-input-first2"
                                                    type={passwordVisible ? "text" : "password"}
                                                    onFocus={floatFocus}
                                                    onBlur={floatBlur}
                                                    onClick={floatFocus}
                                                    placeholder=" "
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <label className="floating-label2">Password</label>
                                                <span className="icon password-icon" onClick={togglePasswordVisibility}>
                                    <i className={`fas ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                </span>
                                            </div>
                                        </div>
                                    </div>
                                
                                        <div className="input-group">
                                            <button type="submit" className="login-button">
                                                Login
                                            </button>
                                        </div>
                                        <div className="login-options">
                                            <a href="/forgotpassword" className="forgot-password">Forgot Password?</a>
                                            <a href="/signup" className="create-account">Create an Account</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Login;
